import shuffle from 'lodash.shuffle';

export const createDeck = async (size) => {
    
    const pokeIds = idGenerator(size);
    const pokeDex = [];

    await Promise.all(pokeIds.map(async (element) => {
        let pokeInfo = await getPokemon(element);
        pokeDex.push(pokeInfo);
        pokeDex.push({ ...pokeInfo });
    }));


    return shuffle(pokeDex);
}

const idGenerator = (size) => {
    const ids = []
    for (let i=0; i<size; i++) {
        const randomNumber = Math.floor(Math.random() * 500) + 1;
        ids.push(randomNumber);
    }
    return ids;
}

const getPokemon = async (element) => {
    let response = await fetch(`https://pokeapi.co/api/v2/pokemon/${element}`);
    let pokemon = await response.json();
    return { name: pokemon.name, img: pokemon.sprites.other.dream_world.front_default };
}