import { useState } from 'react';
import '../App.css';
import ReactCardFlip from 'react-card-flip';
import pb from '../source/pb.png';

const Card = ({pokemon, flipPair}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  // useEffect(()=>{
  //   handleFlipPair();
  // },[isFlipped])

  function handleFlipped () {
    setIsFlipped(!isFlipped);
    //flipPair(true);
    //console.log(isFlipped)
  }

  return (
    <ReactCardFlip isFlipped={isFlipped}>
      <div className='card_front' onClick={handleFlipped}>
        <img src={pb} alt="pokeball" />
      </div>

      <div className='card_back' onClick={handleFlipped}>
        <div className='card'>
          <img src={pokemon.img} alt="poke" />
        </div>
      </div>
    </ReactCardFlip>
  )
}

export default Card;
/*
<div className='card'>
        <img src={pokemon.img} alt="poke" />
    </div>
    <img src={pb} alt="pokeball" />
*/