import Table from './Components/Table';
import Header from './Components/Header';
import './App.css';
import { useState } from 'react';


function App() {
  const [newDeck, setNewDeck] = useState(true);
  const [flipPair, setFlipPair] = useState(0);
  
  function handleNewDeck () {
    setNewDeck(!newDeck);
  }

  function handleFlipPair (poke) {
    if (poke === undefined) return
    setFlipPair(flipPair + 1);
    console.log(flipPair);
  }  

  return (
    <div className="App">
      <Header reload={handleNewDeck} />
      <Table reload={newDeck} flipPair={handleFlipPair}/>
    </div>
  );
}

export default App;
