import logo from '../source/logo2.png';
import '../App.css';

const Header = ({reload}) => {
    return(
        <div className='header_MC'>
            <div className='title'>
                <img src={logo} alt='logo' style={{'height': '5rem'}}/>
            </div>
            <div className='tryNumber'>
                <h2>Intentos: 0</h2>
            </div>
            <div className='reload'>
                <div className='reload_btn' onClick={reload}>
                    <h2>Reiniciar</h2>
                </div>
            </div>
        </div>
    )
}

export default Header;