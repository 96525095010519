import React, { useEffect, useState } from 'react';
import Card from './Card';
import '../App.css';
import { createDeck } from '../utils/createDeck';

const Table = ({reload, flipPair}) => {
  const [deck, setDeck] = useState(null);

  useEffect(()=>{
    const fetchData = async () => {
      let newDeck = await createDeck(12);
      setDeck(newDeck);
      //console.log(newDeck);
    };
    fetchData();
  },[reload])

  return (
    <div className='table'>
        {deck && deck.map((poke)=><Card pokemon={poke} flipPair={flipPair} key={Math.random().toString(36).substr(2, 18)}/>)}
    </div>
  )
}

export default Table;